import { useEffect, useRef, useState } from "react";
import styles from "./countdown.module.scss";
import FixedWidth from "./fixedWidth";

type Props = {
    date: Date;
};

export default function Countdown({ date }: Props) {
    const countdown = useCountDown(date);
    const numbers = useRef("012345678");
    const chars = useRef("DHMS");

    const numberWidth = useLargestCharacter(numbers.current);
    const letterWidth = useLargestCharacter(chars.current);

    return (
        <div className={styles.wrapper}>
            <div>
                {`${padNumber(countdown.days, 2)}`
                    .split("")
                    .map((char, index) => (
                        <FixedWidth key={index} width={numberWidth.width}>
                            {char}
                        </FixedWidth>
                    ))}
                <FixedWidth width={letterWidth.width}>D</FixedWidth>
            </div>
            <div>
                {`${padNumber(countdown.hours, 2)}`
                    .split("")
                    .map((char, index) => (
                        <FixedWidth key={index} width={numberWidth.width}>
                            {char}
                        </FixedWidth>
                    ))}
                <FixedWidth width={letterWidth.width}>H</FixedWidth>
            </div>
            <div>
                {`${padNumber(countdown.minutes, 2)}`
                    .split("")
                    .map((char, index) => (
                        <FixedWidth key={index} width={numberWidth.width}>
                            {char}
                        </FixedWidth>
                    ))}
                <FixedWidth width={letterWidth.width}>M</FixedWidth>
            </div>
            <div>
                {`${padNumber(countdown.seconds, 2)}`
                    .split("")
                    .map((char, index) => (
                        <FixedWidth key={index} width={numberWidth.width}>
                            {char}
                        </FixedWidth>
                    ))}
                <FixedWidth width={letterWidth.width}>S</FixedWidth>
            </div>
        </div>
    );
}

function padNumber(num: number, size: number) {
    const numString = num.toString();
    const numStringLength = numString.length;

    // num is equal to size, and don't need padding
    if (numStringLength === size) return num;

    const zeroes = Array.from({ length: size - numStringLength })
        .fill(0)
        .join("");

    return zeroes + numString;
}

type CountDown = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
};

function useCountDown(date: Date) {
    const [time, setTime] = useState<CountDown>({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const interval = setInterval(function () {
            const countDownDate = date.getTime();
            // Get today's date and time
            const now = new Date().getTime();

            // Find the distance between now and the count down date
            const distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setTime({
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds,
            });

            return () => clearInterval(interval);
        }, 1000);
    }, [date]);

    return time;
}

function getCharacterWidth(character: string) {
    console.log("running: getCharacterWidth");
    let width;

    // Use first character, if multiple is provided
    if (character.length > 1) character = character[0];

    const div = document.createElement("div");
    div.innerText = character;

    // Style element to not be visible
    const elementStyle = `
  position: fixed;
  top: -999px;
  left: -999px:
  visibility: hidden;
  `;

    div.style.cssText = elementStyle;

    // Append and measure div
    document.body.appendChild(div);
    const bounds = div.getBoundingClientRect();
    width = bounds.width;

    // Clean up
    div.remove();

    return width;
}

function useLargestCharacter(characters: string) {
    const [largestCharacter, setLargestCharacter] = useState({
        character: "init",
        width: 0,
    });

    useEffect(() => {
        const getLargestCharacter = () => {
            if (characters.length > 1) {
                characters
                    .split("")
                    .slice(1, characters.length - 1)
                    .forEach((character) => {
                        const width = getCharacterWidth(character);

                        if (width > largestCharacter.width)
                            setLargestCharacter({
                                character: character,
                                width: width,
                            });
                    });
            }
        };

        getLargestCharacter();

        window.addEventListener("resize", getLargestCharacter);
    }, [characters, largestCharacter.width]);

    return largestCharacter;
}
