import Countdown from "./_components/countdown";
import styles from "./app.module.scss";

function App() {
    return (
        <main className={`${styles.grid} ${styles.main}`}>
            <h1 className={styles.col_1}>Dear Jones,</h1>
            <section className={`${styles.col_2} richtext`}>
                <p>
                    Stills- and motion full production services and art buying
                </p>
                <p>Based in Copenhagen with 24/7 international delivery</p>
                <p>
                    Say hello at{" "}
                    <a href="mailto:info@dearjones.tv">info@dearjones.tv</a>
                </p>
                <p>
                    Tel. <a href="tel:004528910120">+45 2891 0120</a>
                </p>
                <p>
                    Follow on{" "}
                    <a
                        href="https://www.instagram.com/dearjones.tv"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Instagram
                    </a>
                </p>
            </section>
            <footer className={`${styles.col_2} ${styles.footer}`}>
                <div className="flex gap-m">
                    <p>Launching 2024</p>
                    <Countdown date={new Date("Apr 1, 2024")} />
                </div>
            </footer>
        </main>
    );
}

export default App;
