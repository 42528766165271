import { ReactNode } from "react";
import styles from "./countdown.module.scss";

type Props = {
  children: ReactNode;
  width: number;
};

export default function FixedWidth({ children, width }: Props) {
  return (
    <span
      className={styles.fixedWidth}
      style={{ width: `${width}px`, height: `${width}px` }}
    >
      {children}
    </span>
  );
}
